<template>
  <div>
    <section class="new-release-headline">
      <h1 class="heading">新着図書</h1>
    </section>
    <section
      v-for="document in results"
      :key="document.id"
      :ref="`searchResultEntry-${document.id}`"
      class="search-result-entry my-5"
      style="margin-top: 32px"
    >
      <v-card width="100%" style="padding: 1px">
        <document-list-item
          :disable-document-link="disableDocumentLink"
          :document="document"
          :disabled="true"
          variant="small"
          class="ma-4"
        />
      </v-card>
    </section>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'nuxt-property-decorator';
import { Context } from '@nuxt/types';
import { MetaInfo } from 'vue-meta';
import DocumentListItem from '@/components/document-list-item.vue';
import { DocRecord } from 'wklr-backend-sdk/models';

const PER_PAGE = 20;

type AsyncData = Pick<NewReleases, 'results'>;

@Component({
  components: { DocumentListItem },
})
export default class NewReleases extends Vue {
  /** 結果 */
  results: DocRecord[] = [];

  head(): MetaInfo {
    return { title: '新着図書' };
  }

  get disableDocumentLink(): boolean {
    return this.$domain.isMHMMypage;
  }

  async asyncData({ app: { $repositories } }: Context): Promise<AsyncData | undefined> {
    return {
      results: await $repositories.docs.recentlyAdded(PER_PAGE),
    };
  }
}
</script>

<style lang="scss" scoped>
.new-release-headline {
  padding: 24px 0px 12px;

  > .heading {
    padding: 15px 0;
    font-size: 28px;
    color: #000000de;
  }
}
</style>
